<template>
  <div>
    <h2 class="c-view-h2">{{$store.state.event.name}}</h2>
    <h1 class="c-view-h1">Mailings</h1>
    <ui-toolbar :actions="toolbar"></ui-toolbar>
    <div class="bg-white rounded border p-4">
      <ui-cards>
        <ui-card v-for="(mailing, index) in filteredMailings" :key="index">
          <template v-slot:head>{{mailing.subject}}</template>
          <p>
            ID: {{mailing.id}}
          </p>
          {{mailing.description}}
          <template v-slot:foot><a class="p-2" target="_blank" :href="editLink(mailing.id)" :title="'In '+$env.whiteLabel.mailProduct+' bearbeiten'"><i class="fal fa-edit"></i></a></template>
        </ui-card>
      </ui-cards>
    </div>
  </div>
</template>

<script>

import {getSID} from "@/store/auth";

export default {
  name: "Mailings",
  data(){
    return {
      filter: false,
      toolbar: [
        {
          title: 'alle Mailings zeigen',
          label: `<i class="fal fa-envelope"></i>`,
          component: 'button',
          type: 'blank',
          action: () => {this.filter = false}
        },
        {
          title: 'Mailings filtern',
          label: `<i class="fal fa-filter"></i>`,
          component: 'button',
          type: 'blank',
          action: () => {this.filter = true}
        }
      ]
    }
  },
  created() {
    this.$store.getMailings()
  },
  computed: {
    filteredMailings(){
      let ret = this.$store.state.mailings
      if(this.filter === false) return ret

      const patt = new RegExp(this.$store.state.event.slug, "i")
      return ret.filter(entry => patt.test(entry.description))
    }
  },
  methods: {
    action(){
      console.log('action')
    },
    editLink(id){
      if(typeof id !== 'undefined'){
        return this.$env.whiteLabel.mailDomain + 'email/Email/getUpdate/'+ id +'?sid='+ getSID()
      }
      return '#'
    }
  }
}
</script>

<style scoped>

</style>
